import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/layout"
import Service from "../../components/service"
// seo import
import { Seo } from "../../components/Seo"

export default class Railgun extends Service {
  constructor(props) {
    super(props)
    this.service = new Service()
    this.service.type = "RailGun"
    this.service.url =
      "/services/" + this.service.type.toLowerCase().replace(" ", "")
    this.service.featuredImg =
      "https://i-a.cloud/cdn-cgi/imagedelivery/YZO2dKh2-nQCM3E2pZE7Kw/bcda9fdf-6adf-459f-69e2-0c9e1b703c00/512"
    this.service.fiOrientation = "landscape"
    this.service.class = "ui-body__text-image"
    this.service.desc = this.description()
  }

  description() {
    return (
      <div>
        <p>
          Multi-Company based investigations. RailGun is an extensive service
          that{" "}
          <strong className="strong-white">
            filters companies for effective targeting
          </strong>
          , reducing the time to impact for{" "}
          <strong className="strong-white">
            sales and marketing objectives
          </strong>
          . Access{" "}
          <strong className="strong-white">
            data-first, digestible insight
          </strong>{" "}
          tailored to your{" "}
          <strong className="strong-white">specific requirements</strong>.
        </p>
      </div>
    )
  }

  render() {
    if (typeof document !== `undefined`) {
      if (window.location.pathname.includes(this.service.url)) {
        return (
          <Layout>
            <div className="ui-body-container products">
              <h1 className="body-title product-title">
                {"IA ".concat("> ").concat(this.service.type)}
              </h1>

              <div className="spacing-divider--extra-small"></div>

              <StaticImage
                src="../../assets/images/spacex-large.jpeg"
                alt="SpaceX rocket launch"
                placeholder="none"
                className="products-img-full"
                loading="lazy"
              />

              <div className="spacing-divider--small"></div>

              <div
                id="text-image-1"
                className="ui-body__text-image scroll-fade"
              >
                <div className="ui-body__text-image--left">
                  <h1 className="body-title">Target With Clarity</h1>
                  <div className="spacing-divider--small no-display-desktop">
                    <div className="no-display-desktop divider-white"></div>
                  </div>
                  <p>
                    Our RailGun service is precision-engineered to{" "}
                    <strong className="strong-white">
                      reduce the time taken investigating
                    </strong>{" "}
                    <strong className="strong-white-u">large lists</strong>{" "}
                    <strong className="strong-white">of companies</strong>. You
                    provide the targets, we provide the results.{" "}
                    <strong className="strong-white">
                      RailGun could be used for a marketing campaign, a list of
                      existing companies or complete unknowns!
                    </strong>
                  </p>
                </div>
                <div className="ui-body__text-image--right ui-body__text-image--right-text">
                  <h1 className="body-title">Identify Key Trends</h1>
                  <div className="spacing-divider--small no-display-desktop">
                    <div className="no-display-desktop divider-white"></div>
                  </div>
                  <p>
                    Manually investigating these companies would take forever,
                    an archaic blanket campaign that will irritate your
                    contacts.{" "}
                    <strong className="strong-white">
                      Railgun reduces the list to a manageable size
                    </strong>{" "}
                    — you can even{" "}
                    <strong className="strong-white">
                      segment companies by keyword areas
                    </strong>{" "}
                    and{" "}
                    <strong className="strong-white">
                      target them with tailored content
                    </strong>
                    !
                  </p>
                </div>
              </div>

              <div className="spacing-divider--small"></div>

              <h1 className="body-title scroll-fade">
                We provide data-driven reports on companies, people and events
                that redefine prospecting and account-based marketing.
              </h1>

              <div className="spacing-divider--small"></div>

              <div
                id="text-image-1"
                className="ui-body__text-image scroll-fade"
              >
                <div className="ui-body__text-image--left">
                  <h1 className="body-title">Patterns in Data</h1>
                  <div className="spacing-divider--small no-display-desktop">
                    <div className="no-display-desktop divider-white"></div>
                  </div>
                  <p>
                    <strong className="strong-white">Railgun Graphs</strong>{" "}
                    offer a{" "}
                    <strong className="strong-white">
                      holistic and comparative view
                    </strong>{" "}
                    of your list of target companies and{" "}
                    <strong className="strong-white">
                      highlights preferred keyword areas
                    </strong>
                    .{" "}
                    <strong className="strong-white">
                      It ranks the keyword areas
                    </strong>{" "}
                    for the given list; invaluable when deciding who to approach
                    and what information to contact them with.
                  </p>
                  <p>
                    Need help reading graphs? Our{" "}
                    <a href="https://wiki.i-a.cloud/en/graphs">Wiki</a> has you
                    covered!
                  </p>
                </div>
                <div className="ui-body__text-image--right">
                  <img
                    src="https://imagedelivery.net/YZO2dKh2-nQCM3E2pZE7Kw/5a1cf839-73c8-4ce5-502a-cd64a7565100/512"
                    alt="Example of Railgun graph"
                    placeholder="none"
                    objectFit="contain"
                    className=""
                    loading="lazy"
                  ></img>
                </div>
              </div>

              <div className="spacing-divider--small"></div>
              <div className="ui-body__full-width">
                <p className="full-width">
                  We also supply a complete list of all the employees on this
                  graph so you can follow up with them on LinkedIn.
                </p>
              </div>
            </div>
          </Layout>
        )
      }
    }
  }
}

export const Head = () => <Seo />
